// src/AthenaTableEditor.js

import React, { useState, useEffect } from 'react';
import { post } from 'aws-amplify/api';
import { downloadData, uploadData } from 'aws-amplify/storage';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Amplify } from 'aws-amplify';

import {
  DataGrid,
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

export default function AthenaTableEditor() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState({});

  // Fetch data on component mount
  useEffect(() => {
    executeQuery();
  }, []);

  const executeQuery = async () => {
    setLoading(true);
    try {
      const apiName = 'athenaapi';
      const path = '/execute-query';
      const options = {
        body: {
          query: 'SELECT * FROM email_threads LIMIT 200;', // Replace 'your_table' with your table name
          database: 'fe-activity', // Replace with your database name
        },
      };

      const restOperation = post({
        apiName,
        path,
        options,
      });
      const { body } = await restOperation.response;
      const response = await body.json();

      if (response && Array.isArray(response) && response.length > 0) {
        // Extract headers (column names)
        const headers = Object.keys(response[0]);
        setColumns([
          ...headers.map((header) => ({
            field: header,
            headerName: header,
            width: 150,
            editable: header === 'client', // Only make the 'client' field editable
          })),
          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }

              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
              ];
            },
          },
        ]);

        // Set the data with unique IDs
        const dataWithId = response.map((row, index) => ({ id: index, ...row }));
        setRows(dataWithId);
      } else {
        setColumns([]);
        setRows([]);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    }
    setLoading(false);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.Edit } }));
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel((prevModel) => ({ ...prevModel, [id]: { mode: GridRowModes.View } }));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? newRow : row)));

    // Add to pendingUpdates
    const threadId = newRow.threadid; // Assuming 'threadid' is the column containing the filename
    setPendingUpdates((prevPending) => ({
      ...prevPending,
      [threadId]: newRow.client, // Store only the updated client value
    }));

    return newRow;
  };

  // Save changes back to S3
  const saveChanges = async () => {
    setLoading(true);
    try {
      // Iterate through pendingUpdates and update each file in S3
      for (const [threadId, updatedClient] of Object.entries(pendingUpdates)) {
        const fileKey = `data/thread_summaries/${threadId}.json`;

        // Download JSON file from S3
        const downloadOperation = await downloadData({
          path: fileKey,
          options: {
            level: 'public',
          },
        }).result;

        const text = await downloadOperation.body.text();
        const jsonData = JSON.parse(text);

        // Update the 'client' field in the JSON data
        jsonData.client = updatedClient;

        console.log('jsonData with updated client:', jsonData)

        // Upload the updated JSON file back to S3
        const jsonString = JSON.stringify(jsonData, null, 2);
        await uploadData({
          path: fileKey,
          data: jsonString,
          options: {
            level: 'public',
            contentType: 'application/json',
          },
        }).result;

        console.log(`Updated file ${fileKey} with new client value: ${updatedClient}`);
      }

      // Clear pending updates after successful upload
      setPendingUpdates({});
      alert('Changes saved successfully!');
    } catch (err) {
      console.error('Error saving changes to S3:', err);
      alert('Error saving changes to S3. See console for details.');
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <h3>Edit Athena Table Data</h3>
        <p>To edit data, double-click on a Client and make your changes. You <b>MUST</b> hit Enter after each change. Click "Save Changes to S3" button to save.</p>
      </Box>

      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          autoHeight
          disableExtendRowFullWidth
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>

      <Box
        sx={{
          flexShrink: 0,
          py: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: '#fafafa',
          borderTop: '1px solid #e0e0e0',
        }}
      >
        <Button variant="contained" color="primary" onClick={saveChanges}>
          Save Changes to S3
        </Button>
      </Box>
    </Box>
  );
}
