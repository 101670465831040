// src/components/ShinyEmbed.js
import React from 'react';

const ShinyApp = () => {
  return (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <h2>Staff Metrics Dashboard</h2>
      <iframe
        title="Shiny App"
        src="https://www.fedashboard.com"
        width="100%"
        height="1000px"
        style={{ border: 'none' }}
        allowFullScreen
      />
    </div>
  );
};

export default ShinyApp;
